import { Component, Renderer2, ViewChild, OnInit, NgZone } from '@angular/core';
import { Platform, MenuController, Config, IonRouterOutlet } from '@ionic/angular';
import { Router, Route, NavigationEnd, NavigationStart, Event, ActivationStart, RoutesRecognized } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthRequestService } from './services/general/auth-request/auth-request';
import { IPlatformFlags, EPlatformDef } from './classes/def/app/platform';
import { SettingsManagerService } from './services/general/settings-manager';
import { UiExtensionService } from './services/general/ui/ui-extension';
import { AnalyticsService } from './services/general/apis/analytics';
import { ScriptLoaderService } from './services/general/script-loader';
import { LocationManagerService } from './services/map/location-manager';
import { BackButtonService } from './services/general/ui/back-button';
import { UserDataService } from './services/data/user';
import { KeyboardProvider } from './services/apis/keyboard';
import { PopupFeaturesService } from './services/app/modules/minor/popup-features';
import { ResourceHandlerDataService } from './services/data/resource-handler';
import { SplitPaneService } from './services/general/ui/split-pane';
import { StorageOpsService } from './services/general/data/storage-ops';
import { SocialAuthWrapperService } from './services/general/auth-request/social-auth';
import { GeneralCache } from './classes/app/general-cache';
import { EOS } from './classes/def/app/app';
import { ICheckLoginResponse } from './classes/def/requests/general';
import { Messages } from './classes/def/app/messages';
import { ErrorMessage } from './classes/general/error-message';
import { routes } from './app-routing.module';
import { ShadowDomService } from './services/general/ui/shadow-dom.service';
import { IPageInterface, EMenuCommand, ERouteDef, ITabInterface } from './app-utils';
import { IDrawerOptions } from './components/generic/components/content-drawer/content-drawer.component';
import { NavParamsService } from './services/app/nav-params';
import { NavUtilsService } from './services/general/ui/nav-utils';
import { ResourcesCoreDataService } from './services/data/resources-core';
import { AppVersionService } from './services/general/app-version';
import { MarkerUtils } from './services/map/marker-utils';
import { ApiDef } from './classes/app/api';
import { IPopoverActions } from './classes/def/app/modal-interaction';
import { AccountManagerService } from './services/app/modules/account-manager';
import { MediaUtilsService } from './services/media/media-utils';
import { FacebookService, InitParams } from 'ngx-facebook';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { WaitUtils } from './services/utils/wait-utils';
import { PromiseUtils } from './services/utils/promise-utils';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild(IonRouterOutlet, { read: IonRouterOutlet, static: false }) outlet: IonRouterOutlet;

  platformFlags: IPlatformFlags = {} as IPlatformFlags;

  // rootPage: any = LandingPage;

  platformType: number;
  authenticated: boolean = false;
  menuOpen: boolean = false;
  menuOpenState: number = 0;
  menuOpenDelay: boolean = false;
  timeoutOpen: any;
  focusObservableSub: Subscription;
  enableBlur: boolean = false;

  appInitComplete: boolean = false;

  /**
   * prevent calling init sequence before it's finished
   */
  appInitInProgress: boolean = false;

  drawerOptions: IDrawerOptions = {
    handleHeight: 50,
    thresholdFromBottom: 200,
    thresholdFromTop: 200,
    bounceBack: true
  };


  transparentBg: boolean = false;

  global = GeneralCache;

  tabs: ITabInterface[] = [
    {
      id: 1,
      url: ERouteDef.home,
      name: "Home",
      icon: "home",
      fullView: false,
      showTabs: true,
      enabled: true
    },
    {
      id: 2,
      url: ERouteDef.support,
      name: "Support",
      icon: "chatbubbles",
      showTabs: false,
      enabled: true
    },
    {
      id: 3,
      url: ERouteDef.marketplace,
      name: "Stories",
      icon: "bookmarks",
      showTabs: false,
      enabled: true
    },
    {
      id: 4,
      url: ERouteDef.map,
      name: "Map",
      icon: "map",
      showTabs: false,
      enabled: true
    },
    {
      id: 5,
      url: ERouteDef.storyline,
      name: "Content",
      icon: "bookmark",
      showTabs: false,
      enabled: false
    },
    {
      id: 10,
      url: ERouteDef.login,
      name: "Login",
      icon: "people",
      fullView: false,
      showTabs: false,
      enabled: true
    },
    {
      id: 11,
      url: null,
      cmd: EMenuCommand.logout,
      name: "Logout",
      icon: "people",
      fullView: false,
      showTabs: true,
      enabled: false
    },
  ]

  menuInitialized: boolean = false;
  showTabs: boolean = false;
  roundedTabMenu: boolean = false;

  activationStarted: boolean = false;
  watchRouterLoaded: BehaviorSubject<boolean>;
  routerLoaded: boolean = false;
  initURL: string = null;

  isWeb: boolean = false;
  webFrameEnabled: boolean = true;

  constructor(
    public router: Router,
    public authRequest: AuthRequestService,
    public plt: Platform,
    public settingsProvider: SettingsManagerService,
    public uiext: UiExtensionService,
    public analytics: AnalyticsService,
    public scriptLoader: ScriptLoaderService,
    public locationManager: LocationManagerService,
    public backButton: BackButtonService,
    public menuCtrl: MenuController,
    public userDataProvider: UserDataService,
    public keyboard: KeyboardProvider,
    public config: Config,
    public renderer: Renderer2,
    public appVersionService: AppVersionService,
    public popupFeatures: PopupFeaturesService,
    public resourceHandler: ResourceHandlerDataService,
    public splitPane: SplitPaneService,
    public storageOps: StorageOpsService,
    public social: SocialAuthWrapperService,
    public shadowDomService: ShadowDomService,
    public nps: NavParamsService,
    public navUtils: NavUtilsService,
    public resourcesCore: ResourcesCoreDataService,
    public accountManager: AccountManagerService,
    public mediaUtils: MediaUtilsService,
    public carouselConfig: NgbCarouselConfig,
    public facebookService: FacebookService,
    public ngZone: NgZone

  ) {
    this.watchRouterLoaded = new BehaviorSubject<boolean>(null);
    console.log("app constructor");
    console.log(this.router.url);
  }


  ngOnInit() {
    this.navUtils.getTransparentBgObservable().subscribe((enabled: boolean) => {
      console.log("transparent bg observable: ", enabled);
      if (enabled != null) {
        this.ngZone.run(() => {
          this.transparentBg = enabled;
        });
      }
    }, (err: Error) => {
      console.error(err);
    });

    this.navUtils.getMenuStateObservable().subscribe((state: boolean) => {
      console.log("tabs menu state observable: ", state);
      if (state != null) {
        this.showTabs = state;
      }
    }, (err: Error) => {
      console.error(err);
    });

    this.navUtils.getRoundedTabStateObservable().subscribe((state: boolean) => {
      console.log("rounded tab state observable: ", state);
      if (state != null) {
        this.roundedTabMenu = state;
      }
    }, (err: Error) => {
      console.error(err);
    });

    this.appVersionService.checkWebProdInit();
    this.initializeApp();

    setTimeout(() => {
      this.initFacebookService();
    }, 1000);
  }


  checkPlatform() {
    // default web
    this.platformType = EPlatformDef.WEB;

    console.log("platform check: ", this.plt.platforms());
    // Disable the split plane in this page
    this.splitPane.setSplitPane(false);
    GeneralCache.os = EOS.browser;
    this.isWeb = true;
  }


  initializeApp() {
    this.plt.ready().then(async () => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need
      console.log("platform ready");
      GeneralCache.tboot = new Date().getTime();
      this.checkPlatform();
      // this.platform_type = platforms.MOBILE; // test mobile
      this.settingsProvider.setPlatform(this.platformType, true);
      this.platformFlags = SettingsManagerService.settings.platformFlags;
      console.log("app ready");
      this.keyboard.standardKeyboardEventInit();
      console.log("running mobile OS code: " + GeneralCache.os);
      console.log("platform flags: ", this.platformFlags);
      // load settings
      this.initLocalProviders();
      this.handleLoginEvents();

      // this.checkActiveRoute();
      this.watchRoute();
      if (!this.platformFlags.WEB) {
        this.routerLoaded = true;
      }
      this.checkLogin();
    });
  }

  watchRoute() {
    this.router.events.subscribe((event: Event) => {

      if (event instanceof RoutesRecognized) {
        if (!this.initURL) {
          console.log("router event init: ", event.url);
          this.initURL = event.url;
        }
      }

      if (event instanceof NavigationStart) {
        console.log("navigation start");
        console.log(this.router.url);
      }

      if (event instanceof NavigationEnd) {
        console.log("navigation end");
        console.log(this.router.url);
        this.watchRouterLoaded.next(true);
        this.checkActiveRoute();
      }

      if (event instanceof ActivationStart) {
        // if (this.activationStarted) {
        //   console.log("activation already started");
        //   this.outlet.deactivate();
        // }
        // this.activationStarted = true;
      }
    });
  }

  checkActiveRoute() {
    let activeRoute: string = this.getActiveRouteUrl();
    console.log("check active route: ", activeRoute);
    this.checkFullViewByURL(activeRoute);
    this.checkTabsEnabledByURL(activeRoute);
  }


  /**
   * change tab on tab menu click
   * @param tab 
   */
  changeTab(tab: ITabInterface) {
    if (!tab) {
      return;
    }
    console.log("change tab: ", tab);
    this.openTab(tab);
  }

  checkTabsEnabledTab(tab: ITabInterface) {
    this.showTabs = tab.showTabs;
  }

  checkFullViewTab(tab: ITabInterface) {
    this.webFrameEnabled = !tab.fullView;
  }


  checkFullViewByURL(url: string) {
    for (let tab of this.tabs) {
      if (tab.url === url) {
        this.checkFullViewTab(tab);
      }
    }
  }

  checkTabsEnabledByURL(url: string) {
    for (let tab of this.tabs) {
      if (tab.url === url) {
        this.checkTabsEnabledTab(tab);
      }
    }
  }


  /**
  * main loader
  * preload resources from server
  * handle user auth
  * retry enabled
  */
  async checkLogin() {
    if (this.appInitInProgress) {
      return;
    }

    this.resourcesCore.triggerApiKeyLoadingRequired();

    this.appInitInProgress = true;
    //await this.uiext.showLoadingV2Queue("Login");
    console.log("check login");
    // this.liveupdate.checkUpdate();
    // check local auth
    this.authRequest.getAuthHeaders().then(() => {
      this.checkLoginCore();
    }).catch(async (err: Error) => {
      console.error(err);
      //await this.uiext.dismissLoadingV2WithSkip(false);
      this.appInitInProgress = false;
      this.authRequest.setLoggedInFlag(false);
      PromiseUtils.wrapNoAction(this.initResourcesCore(), true);
    });
  }

  checkLoginCore() {
    // check server auth
    this.authRequest.checkLoginServer().then(async (respdata: ICheckLoginResponse) => {
      console.log("check login server");
      // show popup if something is wrong with the user account
      // e.g. the email is not validated  

      GeneralCache.sessionExpired = false;

      if (!respdata.valid) {
        this.popupFeatures.checkLoginCode(respdata.respCode, respdata.message);
      }

      try {
        if (!respdata.passwordHash) {
          console.log("require new password after check login");
          await this.popupFeatures.setPasswordWizard();
        }

        this.initResourcesMain(respdata);
      } catch (err) {
        console.error(err);
        this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
        this.initResourcesMain(respdata);
      }

    }).catch(async (err: Error) => {
      // error
      console.log("check login server error: ", err);
      this.authRequest.setLoggedInFlag(false);
      await this.uiext.dismissLoadingV2WithSkip(false);
      this.appInitInProgress = false;

      if (GeneralCache.sessionExpired) {
        try {
          await this.uiext.showRewardPopupQueue(Messages.msg.sessionExpired.after.msg, Messages.msg.sessionExpired.after.sub, null, false, 2000);
          this.authRequest.setLoggedInFlag(false);
          await this.uiext.dismissLoadingV2WithSkip(false);
          await this.accountManager.logout();
          this.checkLogin(); // recursive
        } catch (err) {
          console.error(err);
        }
        return;
      }

      // maybe there is no internet connection, try again
      this.retryOrExitApp(err, () => {
        this.checkLogin();
      });
    });
  }

  initResourcesCore() {
    return new Promise(async (resolve, reject) => {
      let promiseResources: Promise<boolean>[] = [];
      promiseResources.push((new Promise((resolve, reject) => {
        this.resourcesCore.loadApiKeys().then(() => {
          ApiDef.apiKeysLoaded = true;
          console.log("resources loaded (1)");
          resolve(true);
        }).catch((err) => {
          reject(err);
        });
      })));

      promiseResources.push((new Promise((resolve, reject) => {
        this.resourceHandler.getAllResources(false).then(() => {
          console.log("resources loaded (2)");
          resolve(true);
        }).catch((err) => {
          reject(err);
        });
      })));

      Promise.all(promiseResources).then(() => {
        console.log("init resources main complete");
        this.resourceHandler.setupServiceUrl().then(() => {
          console.log("setup service url complete");
        });
        // load google maps w/ api key spec
        // this.scriptLoader.loadGoogleMaps(GeneralCache.useWebGL && (this.platformFlags.WEB || (!this.platformFlags.WEB && GeneralCache.useWebGLMobile))).then(() => {
        //   this.settingsProvider.setMapReadyWatch();
        // });
        resolve(true);
      }).catch((err: Error) => {
        reject(err);
      });
    });
  }

  /**
   * init all resources
   * @param respdata 
   */
  async initResourcesMain(respdata: ICheckLoginResponse) {
    console.log("loading resources");
    await this.uiext.showLoadingV2Queue("Loading resources");
    this.initResourcesCore().then(async () => {
      // authorized
      // check if the user can be also a tester (tester flag in db)
      await this.uiext.dismissLoadingV2WithSkip(false);
      console.log("app init complete");
      this.appInitComplete = true;
      this.appInitInProgress = false;
      this.authRequest.setLoggedInFlag(true);
      this.userDataProvider.afterSetLoggedInFlag(true, respdata);
      this.mediaUtils.initConfig();
    }).catch(async (err: Error) => {
      console.error(err);
      await this.uiext.dismissLoadingV2WithSkip(false);
      this.analytics.dispatchError(err, "main");
      this.appInitInProgress = false;
      this.retryOrExitApp(err, () => {
        // this.initResourcesMain(respdata);
        this.checkLogin();
      });
    });
  }


  /**
   * retry callback
   * exit app otherwise 
   * @param err 
   * @param onRetry 
   */
  retryOrExitApp(err: Error, onRetry: () => any) {
    // timeout just in case, make sure the alert shows up
    console.log("retry or exit app");
    setTimeout(() => {

      let actions: IPopoverActions = {
        retry: {
          name: "Retry loading",
          code: 1,
          enabled: true
        },
        exit: {
          name: "Exit app",
          code: 2,
          enabled: true
        },
        reset: {
          name: "Reset account",
          code: 3,
          enabled: true
        }
      };

      this.uiext.showCustomAlert(Messages.msg.resourceInitFailed.after.msg, ErrorMessage.parseAddBefore(err, Messages.msg.resourceInitFailed.after.sub), actions, false).then((res: number) => {
        switch (res) {
          case 1:
            onRetry();
            break;
          case 2:
            if (GeneralCache.os === EOS.ios) {
              this.uiext.showAlertNoAction(Messages.msg.info.after.msg, "Please use the home button to exit");
            } else {
              this.backButton.exitApp();
            }
            break;
          case 3:
            this.accountManager.logoutPrompt().then(() => {
              this.uiext.showAlert(Messages.msg.loginAgain.after.msg, Messages.msg.loginAgain.after.sub, 1, null).then(() => {
                onRetry();
              }).catch((err: Error) => {
                console.error(err);
                onRetry();
              });
            });
            break;
          default:
            // should not reach here (dismiss is not allowed to prevent unhandled states)
            break;
        }
      }).catch((err: Error) => {
        console.error(err);
        this.analytics.dispatchError(err, "main");
      });

    }, 500);
  }

  initLocalProviders() {
    this.settingsProvider.init(this.platformFlags.IOS);
    MarkerUtils.setPlatform(this.platformFlags);
  }

  /**
   * watch login events
   */
  handleLoginEvents() {
    this.authRequest.checkAuthentication().subscribe((loggedin: boolean) => {
      console.log("login event: ", loggedin);
      if (loggedin !== null) {
        this.authenticated = loggedin;
        this.handleLoginTransition(loggedin);
      }
    }, (err: Error) => {
      console.error(err);
    });
  }

  /**
   * handle login transition
   * @param loggedin
   */
  handleLoginTransition(loggedin: boolean) {

    console.log("login transition detected: ", loggedin);
    console.log("init flags: ", this.appInitComplete, this.appInitInProgress);

    if (loggedin === true) {
      // logged in
      // go to home page only if user was on login page
      // else it should stay on the current page i.e. when page refresh
      if (this.appInitComplete) {
        this.handleLoginTrue();
      } else {
        // load resources first
        // reload init chain
        this.checkLogin();
      }
    } else if (loggedin === false) {
      // not logged in
      this.handleLoginFalse();
    }
  }

  /**
   * gets current active page based on url
   * partial url check (without params)
   */
  private getActiveRouteUrl() {
    let url: string = this.router.url;

    url = url.substring(1);
    let activeRoute: Route = null;
    for (let i = 0; i < routes.length; i++) {
      let r: Route = routes[i];
      if ((r.path.length > 0) && (url.indexOf(r.path) !== -1)) {
        activeRoute = r;
        break;
      }
    }

    console.log("get active route @" + url + ": ", activeRoute);
    return activeRoute ? activeRoute.path : null;
  }

  private waitRouterLoaded() {
    return new Promise((resolve) => {
      WaitUtils.waitFlagResolve(this.routerLoaded, this.watchRouterLoaded, [true], null).then((ok: boolean) => {
        if (ok != null) {
          resolve(true);
        }
      });
    });
  }

  private handleLoginTrue() {
    this.waitRouterLoaded().then(() => {
      let activeRoute: string = this.getActiveRouteUrl();
      let start: boolean = false;

      let loginTab = this.tabs.find(tab => tab.id === 1);
      let logoutTab = this.tabs.find(tab => tab.id === 2);
      loginTab.enabled = false;
      logoutTab.enabled = true;

      // tutorial seen, proceed to home page
      if (this.platformFlags.WEB) {
        if (activeRoute) {
          console.log("active route " + activeRoute);
          if (activeRoute === ERouteDef.login || activeRoute === ERouteDef.signup || activeRoute === ERouteDef.landing) {
            start = true;
          } else {
            // stay on current page
          }
        } else {
          start = true;
        }
      } else {
        start = true;
      }

      if (start) {
        console.log("start");
        if (this.initURL !== ("/" + ERouteDef.login) && this.initURL !== "/") {
          // navigate to typed-in URL (restore navigation after login)
          console.log("restore requested URL: ", this.initURL);
          this.router.navigate([this.initURL], { replaceUrl: true }).then(() => {
            // never ever do this: this.rootPage = page; !! this would load the page twice and mess up the initialization logic
          }).catch((err: Error) => {
            console.error(err);
          });
        } else {
          this.setRootPage(ERouteDef.home);
        }
      }
    });

  }

  private handleLoginFalse() {
    this.waitRouterLoaded().then(() => {
      let activeRoute: string = this.getActiveRouteUrl();
      let loginTab = this.tabs.find(tab => tab.id === 1);
      let logoutTab = this.tabs.find(tab => tab.id === 2);
      loginTab.enabled = true;
      logoutTab.enabled = false;

      // request reload all resources 
      // for e.g. switching user accounts
      this.resourceHandler.clearAllResources();
      this.appInitComplete = false;

      if (activeRoute) {

      } else {
        this.setRootPage(ERouteDef.home);
      }
    });
  }


  isActive(page: IPageInterface) {
    let url: string = this.router.url;
    url = url.substring(1);
    // console.log("isactive: ", url, page.url, url === page.url);
    return url === page.url;
  }

  isActiveTab(tab: ITabInterface) {
    let url: string = this.router.url;
    url = url.substring(1);
    if (!tab.url) {
      return tab.active;
    }
    return url === tab.url;
  }

  /**
   * check special commands that may be attributed to a menu item
   * @param cmd 
   */
  checkMenuCmd(cmd: number) {
    let withCmd: boolean = true;
    switch (cmd) {
      case EMenuCommand.logout:
        // Give the menu time to close before changing to logged out
        // this.userData.logout();
        this.authRequest.logout().then(() => {
          this.setRootPage(ERouteDef.home);
        }).catch((err: Error) => {
          console.error(err);
          this.setRootPage(ERouteDef.home);
        });
        break;
      case EMenuCommand.exit:
        this.backButton.exitApp();
        break;
      default:
        withCmd = false;
        break;
    }
    return withCmd;
  }


  /**
   * open page
   * set root page
   * set open view
   * @param tab 
   */
  openTab(tab: ITabInterface) {
    console.log("openPage: " + tab.name + " @" + tab.url);
    if (!this.checkMenuCmd(tab.cmd)) {
      if (!tab.url) {
        return;
      }
      this.navUtils.registerNavChanged();
      this.uiext.resetOverlay();

      this.checkFullViewByURL(tab.url);
      this.checkTabsEnabledByURL(tab.url);

      this.setRootPageCore(tab.url).then(() => {
        this.navUtils.setOpenView(tab);
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }


  private setRootPage(url: string) {
    this.setRootPageCore(url).then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  private setRootPageCore(url: string) {
    let promise = new Promise((resolve, reject) => {
      console.log("setting root page: ", url);

      if (url == null) {
        reject(new Error("undefined page url"));
        return;
      }

      this.backButton.reset();

      // clear nav params
      this.nps.clearAll();

      this.router.navigate([url], { replaceUrl: true }).then(() => {
        // never ever do this: this.rootPage = page; !! this would load the page twice and mess up the initialization logic
        resolve(true);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  initFacebookService(): void {
    const initParams: InitParams = { xfbml: true, version: 'v15.0' };
    this.facebookService.init(initParams);
  }
}
